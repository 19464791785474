const { defaultLanguage } = require("../../prismic-configuration");

/* The Link Resolver
 * This function will be used to generate links to Prismic documents */

const linkResolver = (doc) => {
  const properties = doc._meta || doc;
  //homepage

  if (properties.type === "headeritem") {
    return properties.lang === defaultLanguage ? "/" : `/${properties.lang}`;
  }

  //why smartwealth page
  if (properties.type === "whysmartwealth") {
    return properties.lang === defaultLanguage
      ? `/warumsmartwealth`
      : `/${properties.lang}/whysmartwealth`;
  }

  //about us page
  if (properties.type === "aboutus") {
    return properties.lang === defaultLanguage
      ? `/uberuns`
      : `/${properties.lang}/aboutus`;
  }

  //contact us page
  if (properties.type === "contactus") {
    return properties.lang === defaultLanguage
      ? `/kontaktiereuns`
      : `/${properties.lang}/contactus`;
  }

  //privacy policy page
  if (properties.type === "privacypolicy") {
    return properties.lang === defaultLanguage
      ? `/datenschutzbestimmungen`
      : `/${properties.lang}/privacypolicy`;
  }

  //private clients
  if (properties.type === "privateclient") {
    return properties.lang === defaultLanguage
      ? `/privatkunden`
      : `/${properties.lang}/privateclient`;
  }

  //institutional clients
  if (properties.type === "institutionalclients") {
    return properties.lang === defaultLanguage
      ? `/institutionellekunden`
      : `/${properties.lang}/institutionalclients`;
  }

  //b2b
  if (properties.type === "b2b") {
    return properties.lang === defaultLanguage
      ? `/b2b`
      : `/${properties.lang}/b2b`;
  }

  //customer information page
  if (properties.type === "customerinformation") {
    return properties.lang === defaultLanguage
      ? `/kundeninformation`
      : `/${properties.lang}/customerinformation`;
  }

  //impressum page
  if (properties.type === "imprint") {
    return properties.lang === defaultLanguage
      ? `/impressum`
      : `/${properties.lang}/imprint`;
  }

  //blog post page
  if (properties.type === "blogpost") {
    var link = String(properties.data?.title[0]?.text)
      .toLowerCase()
      .replace(/ /g, "-");
    if (link !== "undefined") {
      return properties.lang === defaultLanguage
        ? `/blog/${link}`
        : `/${properties.lang}/blog/${link}`;
    }
    return properties.lang === defaultLanguage
      ? `/blog`
      : `/${properties.lang}/blog`;
  }
  // brochures
  if (properties.type === "brochures") {
    return properties.lang === defaultLanguage
      ? `/brochures`
      : `/${properties.lang}/brochures`;
  }
  
  //product/3iq
  if (properties.type === "3iq") {
    return properties.lang === defaultLanguage
      ? `/product/3iq`
      : `/${properties.lang}/product/3iq`;
  }

  return "/";
};

module.exports = linkResolver;
